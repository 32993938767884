<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['skinDryness'])">
      <single-answer-question
        title="label.skinDryness"
        :value="skinDryness"
        :options="skinDrynessOptions"
        dysfunction="skin-dryness"
        @input="onFieldChange('skinDryness', $event)"
      />
    </question-with-error-wrap>

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="hasSomeDryness"
        class="q-mt24 q-mt32-md"
        :error="getFieldsError(['facialDrynessLocations'])"
      >
        <face-zone-single-select
          id="facial-dryness-locations"
          title="label.facialDrynessLocations"
          :gender="gender"
          :value="facialDrynessLocations"
          :options="drynessLocationsOptions"
          field-name="dryness"
          @input="onFieldChange('facialDrynessLocations', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="drynessCausedByClimateVisible"
        class="q-mt24 q-mt32-md"
        :error="getFieldsError(['drynessCausedByClimate'])"
      >
        <single-answer-question
          id="dryness-caused-by-climate"
          :title="climateQuestionTitle"
          :value="drynessCausedByClimate"
          :options="drynessCausesOptions"
          dysfunction="skin-dryness"
          @input="onFieldChange('drynessCausedByClimate', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="hasSomeDryness"
        class="q-mt24 q-mt32-md"
        :error="getFieldsError(['drynessOccurrence'])"
      >
        <multi-answer-question
          id="dryness-occurrence"
          title="label.drynessOccurrence"
          hint="label.selectAllThatApply"
          :value="drynessOccurrence"
          :options="drynessOccurrenceOptions"
          dysfunction="skin-dryness"
          @input="onFieldChange('drynessOccurrence', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>
  </div>
</template>

<script>
import FadeInTransitionWrap from '@/components/wrappers/FadeInTransitionWrap';
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';
import FaceZoneSingleSelect from '@/modules/questionnaire/new-design-components/questions/face-zone-input/FaceZoneSingleSelect';
import MultiAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/multi-answer-question/MultiAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { AMOUNT_OPTIONS } from '@/modules/questionnaire/constants/questions';
import {
  DRYNESS_LOCATIONS_OPTIONS,
  DRYNESS_CAUSES_OPTIONS,
  DRYNESS_OCCURRENCE_OPTIONS
} from '@/modules/questionnaire/constants/steps/skinDryness';
import {
  flagAiSelectedOption,
  flagAiSelectedArrayOptions
} from '@/modules/questionnaire/api/helpers';

export default {
  name: 'DrynessTemplate',
  components: {
    FadeInTransitionWrap,
    QuestionWithErrorWrap,
    SingleAnswerQuestion,
    FaceZoneSingleSelect,
    MultiAnswerQuestion
  },
  mixins: [stepTemplateMixin],
  props: {
    gender: {
      type: String,
      required: true
    },
    skinDryness: {
      type: String,
      default: ''
    },
    skinDrynessAiSelected: {
      type: String,
      default: ''
    },
    facialDrynessLocations: {
      type: String,
      default: ''
    },
    facialDrynessLocationsAiSelected: {
      type: String,
      default: ''
    },
    hasSomeDryness: {
      type: Boolean,
      default: false
    },
    drynessCausedByClimate: {
      type: String,
      default: ''
    },
    drynessCausedByClimateAiSelected: {
      type: String,
      default: ''
    },
    drynessCausedByClimateVisible: {
      type: Boolean,
      default: false
    },
    drynessOccurrence: {
      type: Array,
      default: () => []
    },
    drynessOccurrenceAiSelected: {
      type: Array,
      default: () => []
    },
    city: {
      type: String,
      default: ''
    }
  },
  computed: {
    climateQuestionTitle() {
      return this.$t('label.drynessCausedByClimate', [this.city]);
    },
    skinDrynessOptions() {
      return flagAiSelectedOption(AMOUNT_OPTIONS, this.skinDrynessAiSelected);
    },
    drynessLocationsOptions() {
      return flagAiSelectedOption(DRYNESS_LOCATIONS_OPTIONS, this.facialDrynessLocationsAiSelected);
    },
    drynessCausesOptions() {
      return flagAiSelectedOption(DRYNESS_CAUSES_OPTIONS, this.drynessCausedByClimateAiSelected);
    },
    drynessOccurrenceOptions() {
      return flagAiSelectedArrayOptions(
        DRYNESS_OCCURRENCE_OPTIONS,
        this.drynessOccurrenceAiSelected
      );
    }
  }
};
</script>
